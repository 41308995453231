<template>
  <Popup ref="popupRef" width="500px" title="开票信息填写" confirm-text="提交" :confirmLoading="loading" @confirm="confirm" @close="resetData">
    <el-form>
      <el-form-item label="发票抬头" required>
        <el-input placeholder="请输入发票抬头" v-model="invoiceHeader"></el-input>
      </el-form-item>
      <el-form-item label="发票信息" required>
        <el-input placeholder="请输入发票信息" v-model="invoiceInfo"></el-input>
      </el-form-item>
    </el-form>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import { modifiedOrderInvoiceApi } from '@/api/admin.js'
export default {
  components: {
    Popup
  },
  data() {
    return {
      cid: '',
      invoiceHeader: '',
      invoiceInfo: '',
      loading: false
    }
  },
  methods: {
    open(option) {
      if(option.cid) {
        this.cid = option.cid
        this.$refs.popupRef.open()
      }
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetData() {
      this.cid = ''
      this.invoiceHeader = ''
      this.invoiceInfo = ''
      this.loading = false
    },
    confirm() {
      if(!this.invoiceHeader.trim()) return this.$tips({message: '发票抬头不能为空', type:'warning'})
      if(!this.invoiceInfo.trim()) return this.$tips({message: '发票信息不能为空', type:'warning'})
      const formData = {
        cid: this.cid,
        invoiceHeader: this.invoiceHeader.trim(),
        invoiceInfo: this.invoiceInfo.trim(),
        invoiceStatus: 2
      }
      this.loading = true
      console.log('formData', formData)
      modifiedOrderInvoiceApi(formData).then(res => {
        console.log('开票结果', res)
        this.loading = false
        this.$emit('update')
        this.close()
      }).catch(err => {
        this.loading = false
        this.$tips({message: err, type:'error'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>